import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import { XYChartController } from './xy_chart_component_controller'
import { BaseChartController } from './base_chart_component_controller'

export class BarChartController extends XYChartController {
  static values = merge(cloneDeep(BaseChartController.values), {
    xAxisCategories: Array
  })

  seriesType () {
    return 'bar'
  }

  axisTypes () {
    return merge(super.axisTypes(), {
      category: {
        type: 'category',
        data: this.xAxisCategories,
        axisLabel: {
          interval: 0,
          overflow: 'break',
          showMinLabel: true,
          showMaxLabel: true,
          hideOverlap: false
        }
      }
    })
  }

  tooltipFormatters () {
    return merge(super.tooltipFormatters(), {
      total: (params) => this.totalFormatter(params)
    })
  }

  totalFormatter (params) {
    let total = 0
    const seriesHtml = params.map((series) => {
      total += series.value.y
      return `
        <div style="margin: 10px 0 0;line-height:1;">
          ${series.marker}
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${series.seriesName}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${series.value.y}</span>
          <div style="clear:both"></div>
        </div>
      `
    }).join(' ')

    return `
      <div style="margin: 0px 0 0;line-height:1;">
        <div style="margin: 0px 0 0;line-height:1;">
          <span style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params[0].axisValueLabel}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${total}</span>
          <div style="clear:both"></div>
        </div>
        <div style="margin: 0px 0 0;line-height:1;">
          ${seriesHtml}
        </div>
        <div style="clear:both"></div>
      </div>
    `
  }
}

export default BarChartController
